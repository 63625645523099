import { useEffect, useState } from "react";

// import stiluri și componente existente
import styles from "./App.module.css";
import stylesNav from "../src/Components/Header/Header.module.css";

// import alte componente
import Header from './Components/Header/Header';
import { InlineWidget } from "react-calendly";
import MAP from "./Components/Map/Map";
import Snowfall from 'react-snowfall';
import MasonryLayout from "./Components/MasonryLayout/MasonryLayout";

import images from "./Jsons/Images.json"
import ContainerCard from "./Components/ContainerCard/ContainerCard";

// Componenta clona
const AboutMePage = () => {
	const ddItems = [
		{
			id: 1,
			title: "Featured",
			active: true,
			folder: false
		},
		{
			id: 2,
			title: "Studio & Lifestyle",
			active: false,
			folder: 'lifestyle'
		},
		{
			id: 3,
			title: "Product",
			active: false,
			folder: 'product'
		},
		{
			id: 4,
			title: "Automotive",
			active: false,
			folder: 'automotive'
		},
	]

	const [categoryImage, setCategoryImage] = useState(images.categories.featured)
	const [currentChannelId, setCurrentChannelId] = useState('UCt1jBjgjUpdQ4HB57R7BTHA');
	const [videos, setVideos] = useState([]);

	const baseUrl = 'https://wrapapi.com/use/horoscopulazi/alinzvincacom/youtube/0.0.1?wrapAPIKey=Wf16jTpl2Y1kNiGOfraoEQwbgc6mMM1d';

	useEffect(() => {
		(async () => {
			if (currentChannelId) {
				try {
					const data = await fetch(`${baseUrl}`).then(response => response.json());
					const regex = /href="([^"]+)"/;
					const str = data.data["first video"];
					const match = regex.exec(str);

					if (match) {
						setVideos(match[1]);
					} else {
						console.log('No match found');
					}
				} catch (error) {
					console.log(error);
				}
			}
		})();
	}, []);

	const takeDdTitle = (ddTitle) => {
		setCategoryImage(() => {
			const categoryChoose = ddTitle.folder

			// return categoryChoose ? imageList : [...images.categories['featured']]

			switch (ddTitle.id) {
				case 1: {
					return [...images.categories['featured']];
					break;
				}
				case 2: {
					const imagesFolder = require.context('../public/img/lifestyle/compressed/pictures', true);
					const imageList = imagesFolder.keys().map(image => imagesFolder(image)).reverse();

					return imageList;
					break;
				}
				case 3: {
					const imagesFolder = require.context('../public/img/product/compressed/pictures', true);
					const imageList = imagesFolder.keys().map(image => imagesFolder(image)).reverse();

					return imageList;
					break;
				}
				case 4: {
					const imagesFolder = require.context('../public/img/automotive/compressed/pictures', true);
					const imageList = imagesFolder.keys().map(image => imagesFolder(image)).reverse();

					return imageList;
					break;
				}
				default: {
					return [...images.categories['featured']];
					break;
				}
			}
		})
	}

	return (
		<>
			<Header short />

			{/* Secțiunea About Me */}
			<div className={styles['about-me-section']}>
				<div className={styles['about-me-content']}>
					<img
						src={'./img/profile.jpeg'} // Înlocuiește cu linkul fotografiei tale
						alt="Profil"
						className={styles['profile-photo']}
					/>
					<div className={styles['bio-text']}>
						<h2>Despre Mine</h2>
						<p>
							{/* Adaugă descrierea/biografia ta aici */}
							Salut! Sunt Alin Zvinca, fotograf profesionist cu peste 12 ani de experiență, în vârstă de 29 de ani. Născut în județul Botoșani și stabilit în Iași, mă dedic artei fotografiei la cele mai înalte standarde, oferind servicii de top în zona de nord-est a țării.
							<br />
							<br />
							Lucrez alături de clienții mei pentru a surprinde exact ceea ce își doresc, creând imagini care nu doar impresionează, ci și spun o poveste autentică. Stilurile mele de specialitate includ fotografia lifestyle, automotive și portret, domenii în care m-am remarcat prin capturarea unor momente autentice și expresive.
							<br />
							<br />
							Cu o abordare personalizată și un ochi format pentru detalii, creez unele dintre cele mai profesionale și spectaculoase fotografii din zonă, transformând fiecare sesiune foto într-o experiență memorabilă.
							<br />
							<br />
							Ne vedem la studio! <picture>
								<source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/2764_fe0f/512.webp" type="image/webp" />
								<img src="https://fonts.gstatic.com/s/e/notoemoji/latest/2764_fe0f/512.gif" alt="❤" width="20" height="20" />
							</picture>
						</p>
					</div>
				</div>
			</div>

			{/* Secțiunea MAP */}
			<MAP christmas />
			<div className="flex justify-content-center" style={{ marginTop: "50px", padding: '50px' }}>
				<ContainerCard>
					<MasonryLayout images={categoryImage} />
				</ContainerCard>
			</div>
		</>
	);
};

export default AboutMePage;