// import styles of this component
import styles from "./Header.module.css"

// import other components
import ContainerCard from '../ContainerCard/ContainerCard';
import Nav from "../Nav/Nav"
import BrickLayout from "../BrickLayout/BrickLayout";
import HeaderBoxes from "./HeaderBoxes/HeaderBoxes";

// import something from react packages
import { SearchNormal1 } from "iconsax-react";
import { Setting4 } from "iconsax-react";

// import jsons
import JsonHeader from '../../Jsons/HeaderBoxes.json';
import { useState } from "react";

// Header component
const Header = ({ christmas = false, short = false }) => {

  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <header className={`${styles.header} flex justify-content-center nu-e-wordpress-ha-ha-ha ${christmas ? styles["christmas"] : ''} ${short ? styles["short"] : ''} ${menuOpen ? styles["menuOpen"] : ''}`}>
      <ContainerCard className="flex flex-column">
        <div className={`${styles["blur-circle-shape"]}  ${christmas ? styles["christmas"] : ''}`}></div>

        <Nav setParentMenuOpen={setMenuOpen} />
        <BrickLayout christmas={christmas} />

        <div className={`${styles["headings-header"]} flex justify-content-center flex-column `}>
          <h2 className={styles["heading-header-title"]}>
            {
              christmas === true ? <small>
                <i>"Rudolph the Red-Nosed Reindeer <br />
                  Had a very shiny nose"</i>
              </small> : <small>
                <i>"Dont be afraid to work, if you're rewarded."</i>
              </small>}
          </h2>
          <h1 className={styles["heading-header-second-title"]}>
            {
              christmas === true ?
                <>UN CRACIUN <span>ABSOLUT MEMORABIL</span></> : <>THE SEARCH <span>HAS ENDED HERE!</span></>
            }
          </h1>

          <h3 className={styles["heading-header-signature"]}>Alin Zvinca</h3>

          {/* <div className={`${styles["search-bar"]} flex align-items-center`}>
            <SearchNormal1 size="30" color="var(--white-100)" />
            <input type="text" className={styles["search-input"]} placeholder="Search who can change the world" />
            <button className={`${styles["search-btn"]} flex justify-content-center align-items-center`}>
              <Setting4 size="20" color="var(--dark-900)" />
            </button>
          </div> */}

          {/* <HeaderBoxes titles_numbers={JsonHeader.informations} /> */}
          <div className={styles["socials"]}>
            <img src={'./img/tiktok.png'} className={styles["socials--tiktok"]} onClick={e => window.open('https://www.tiktok.com/@zvincaalin', '_blank')}></img>
            <img src={'./img/instagram.png'} className={styles["socials--instagram"]} onClick={e => window.open('https://www.instagram.com/imalinzvinca/', '_blank')}></img>
            <img src={'./img/facebook.png'} className={styles["socials--facebook"]} onClick={e => window.open('https://www.facebook.com/alinionut.zvinca', '_blank')}></img>
            <img src={'./img/twitch.png'} className={styles["socials--twitch"]} onClick={e => window.open('https://twitch.tv/alinzvinca', '_blank')}></img>
            <img src={'./img/youtube.png'} className={styles["socials--youtube"]} onClick={e => window.open('https://www.youtube.com/@AlinZvinca', '_blank')}></img>
            <img src={'./img/mail.webp'} className={styles["socials--mail"]} onClick={e => window.open('mailto:zvanca.alin@yahoo.ro', '_blank')}></img>
          </div>
          {!short && <div className={styles["scroll-down"]} onClick={() => document.getElementById('bangers').scrollIntoView({ behavior: 'smooth' })}>
            <img src={'./img/arrow-down.png'} /> Ce mai pot face? &nbsp;<picture>
              <source srcset="https://fonts.gstatic.com/s/e/notoemoji/latest/1f92b/512.webp" type="image/webp" />
              <img src="https://fonts.gstatic.com/s/e/notoemoji/latest/1f92b/512.gif" alt="🤫" height="70" style={{ fontSize: '30px', height: '30px', marginRight: 0 }} />
            </picture>
          </div>
          }
        </div>
      </ContainerCard>
    </header >
  )
}

export default Header