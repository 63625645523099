import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import Christmas from './Christmas';
import AboutMePage from './AboutMe'; // importă componenta AboutMePage
import './index.css';

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
	<Router>
		<Routes>
			<Route path="/" element={<Christmas />} />
			<Route path="/non-christmas" element={<App />} />
			<Route path="/aboutme" element={<AboutMePage />} />
			{/* Adaugă alte rute aici, dacă e nevoie */}
		</Routes>
	</Router>
);