// import styles of this component
import styles from "./Nav.module.css"

// import other components
import Button from "../Elements/Button/Button"

// import other react pkg to use
import { HambergerMenu } from "iconsax-react"
import { useState } from "react"
import { Link } from "react-router-dom"

// Nav component
const Nav = ({ setParentMenuOpen }) => {
    const [menuOpen, setMenuOpen] = useState(false);
    return (
        <>{menuOpen && <div className={`flex ${styles["mobile-navbar"]}`} onClick={() => { setMenuOpen(!menuOpen); setParentMenuOpen(!menuOpen) }}>
            <ul className={`flex align-items-center`}>
                <li className={`${styles["nav-item"]} ${styles.active}`}>
                    <a href="https://shootme.alinzvinca.com" className={styles["nav-link"]}>Try the Automotive Experience</a>
                </li>
                <li className={`${styles["nav-item"]} ${styles.active}`}>
                    <a href="https://alinzvinca.gumroad.com" className={styles["nav-link"]}>Presets</a>
                </li>
                <li className={`${styles["nav-item"]} ${styles.active}`}>
                    <Link to="/aboutme" className={styles["nav-link"]}>About me</Link>
                </li>
            </ul>
        </div>}
            <nav className={`${styles.nav} flex align-items-center`}>
                <Link to="/" ><h1 className={`${styles["nav-title"]} ${styles["nav-logo"]}`}><img src={'./img/logo.png'} /></h1></Link>
                <ul className={`flex align-items-center ${styles["navbar-nav"]}`}>
                    <li className={`${styles["nav-item"]} `}>
                        <a href="https://shootme.alinzvinca.com" className={styles["nav-link"]}>Try the Automotive Experience</a>
                    </li>
                    <li className={`${styles["nav-item"]} `}>
                        <a href="https://alinzvinca.gumroad.com" className={styles["nav-link"]}>Presets</a>
                    </li>
                    <li className={`${styles["nav-item"]} `}>
                        <Link to="/aboutme" className={styles["nav-link"]}>About me</Link>
                    </li>
                    {/* <li className={styles["nav-item"]}>
                <a href="" className={styles["nav-link"]}>Wallpapers</a>
            </li>
            <li className={styles["nav-item"]}>
                <a href="" className={styles["nav-link"]}>Collections</a>
            </li>
            <li className={styles["nav-item"]}>
                <a href="" className={styles["nav-link"]}>Artists</a>
            </li>
            <li className={`${styles["nav-item"]} ${styles["d-none-1100"]}`}>
                <a href="" className={styles["nav-link"]}>Explore</a>
            </li>
            <li className={`${styles["nav-item"]} ${styles["d-none-1100"]}`}>
                <a href="" className={styles["nav-link"]}>Blog</a>
            </li> */}
                </ul>
                <div className={`flex ${styles["navbar-buttons"]}`}>
                    <Button onClick={() => window.location = 'mailto:zvanca.alin@yahoo.ro'} className={styles['contact-button']}>Contact</Button>
                    <Button onClick={() => window.location = 'https://maps.app.goo.gl/qfzeqDxBHvH9dwDT8'} className={styles['contact-button']}>Locatie</Button>
                </div>
                <div className={styles["navbar-responsive-menu"]}>
                    <Button theme="transparent">
                        <HambergerMenu size="32" color="var(--white-100)" onClick={() => { setMenuOpen(!menuOpen); setParentMenuOpen(!menuOpen) }} />
                    </Button>
                </div>

            </nav>
        </>
    )
}

export default Nav